.success-page {
  background: #3742FA;
  min-height: 100vh;
  flex-direction: column;

  img, .success-text {
    padding-bottom: 100px;
  }

  .success-text {
    display: flex;
    flex-direction: column;

    span {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  button {
    background-color: #FFFFFF;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #3742FA;
  }
}