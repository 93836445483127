* {
  box-sizing: border-box;
}

body {
  background: #FAFAFD;
}

.active-route {
  //color: red;

  div {
    color: #3742FA;

    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
     display: block;
    }
  }
}

a {
  text-decoration: none;
}

.align-centered {
  display: flex;
  align-items: center;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  cursor: pointer;
}

//onfocuse outline none
textarea:focus, input:focus, button:focus, select:focus {
  outline: none;
}

h1 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 30px;

  color: #242232;
}

h3 {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #242232;
}

.suggestions-wrapper {
  z-index: 999999;
  margin-top: 12px;

  .suggestions-suggestions {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    margin: 0 -20px 0 -20px;
    padding: 0 20px 0 20px;
    position: relative;
  }
  mark {
    background-color: #fdffac;
    padding: 2px;
    z-index: -1;
    position: relative;
  }

  .suggestions-suggestion {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 13px;
    line-height: 17px;
    background-color: transparent;
    &:hover .suggestions-value{
      border-bottom: #3742FA 1px solid;
      background-color: transparent;
    }
  }
}

.uppy-Dashboard-inner {
  border: none;
  width: 100%!important;
  background: #fff;


  .uppy-DashboardAddFiles {
    margin: 0;
  }
}